import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import Logo1 from "../../resources/images/logo1.svg"
import Logo2 from "../../resources/images/logo2.svg"
import Logo3 from "../../resources/images/logo3.svg"
import Logo4 from "../../resources/images/logo.svg"
import ScrollIcon from "./ScrollIcon"

const IntroBlock = styled.div`
    margin-top: 76px;
    height: 100vh;
    text-align: center;
    background: ${colors.background};
`

const Logo = styled.div`
    background: url(${props => props.logo}) center center no-repeat;
    width: 100%;
    height: 100%;
`

const IconContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: calc(50% - 15px);
`

const Hero = () => {
  const result = Math.random()
  return (
    <IntroBlock>
      <Logo logo={result < 0.25 ? Logo1 : result < 0.5 ? Logo2 : result < 0.75 ? Logo3 : Logo4 }/>
      <IconContainer onClick={() => window.scrollTo(0,window.innerHeight)}>
        <ScrollIcon />
      </IconContainer>
    </IntroBlock>
  )
}

export default Hero