import React from "react";

import styled from "styled-components";
import colors from "../../styles/colors";
import {Col, Container, Row} from "styled-bootstrap-grid";
import SectionTitle from "../Core/SectionTitle";
import Circle from "../Layout/Circle";

const CareersBlock = styled.div`
    background-color: ${colors.background};
    padding-top: 7em;
    padding-bottom: 7em;
    position: relative;
    overflow: hidden;
`

const Subtitle = styled.h3`
  font-family: Helvetica, sans-serif;
  text-align: center;
  margin-top: 0.3em;
  font-size: 38px;
  letter-spacing: 1px;
  font-weight: bold;
  color: ${colors.foreground};
`;

const Description = styled.p`
  font-family: Helvetica, sans-serif;
  text-align: center;
  margin-top: 1em;
  font-size: 17px;
  line-height: 1.4;
  color: ${colors.foreground};
`;

const Button = styled.button`
  display: block;
  width: 160px;
  margin-top: 40px;
  margin-right: auto;
  margin-left: auto;
  background-color: ${colors.foreground};
  padding: 12px 20px;
  border-radius: 5px;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.1px;
  text-decoration: none;
  border-width: 0;
  font-weight: bold;
  cursor: pointer;
  :hover {
    opacity: 0.85;
    outline: 0;
  }
`;

const Foreground = styled.div`
  z-index: 1;
  position: relative;
`

const Careers = () => {
    return <CareersBlock>
        <Container>
            <Row>
                <Col sm={12}>
                    {/*<Circle color={colors.foreground} right={-5} height={5} width={5} top={0}/>*/}
                    {/*<Circle color={colors.accent1} right={-5} height={6} width={6} top={3}/>*/}
                    {/*<Circle color={colors.accent2} right={-2} height={5} width={5} top={2}/>*/}
                    <Circle color={"linear-gradient(13deg,#d96666 23.65%,#f599b1 54.48%,#bcaff4)"} right={-3} height={5} width={5} top={-4}/>
                    <Foreground>
                        <SectionTitle text={"Careers"} color={colors.accent1} />
                        <Subtitle>Want to work with us?</Subtitle>
                        <Description>
                            We're looking for talented people who can help us<br /> make our mission a reality.
                        </Description>
                        <Button onClick={(e) => {
                            window.location.href = "mailto:rekrutacja@irie.work";
                            e.preventDefault();
                        }}>
                            Contact
                        </Button>
                    </Foreground>
                </Col>
            </Row>
        </Container>
    </CareersBlock>;
}

export default Careers;