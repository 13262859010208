import React from "react";
import styled from "styled-components"

const CircleBox = styled.div`
  background: ${props => props.color};
  border-radius: 9999px;
  position: absolute;
  height: ${props => props.height}rem;
  top: ${props => props.top}rem;
  width: ${props => props.width}rem;
`;

const CircleBoxLeft = styled(CircleBox)`
  left: ${props => props.left}rem;
`

const CircleBoxRight = styled(CircleBox)`
  right: ${props => props.right}rem;
`
const Circle = ({color, width, height, left, right, top}) => {
    return left ? <CircleBoxLeft
        color={color}
        width={width}
        height={height}
        left={left}
        top={top}
    />: <CircleBoxRight
        color={color}
        width={width}
        height={height}
        right={right}
        top={top}
    />
}

export default Circle;