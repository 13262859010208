import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/seo"
import Hero from "../components/Home/Hero"
import Intro from "../components/Home/Intro"
import Contact from "../components/Home/Contact"
import Careers from "../components/Home/Careers";

const IndexPage = () => (
  <Layout isIndex={true}>
    <SEO title="Irie Technology" />
    <Hero />
    <Intro />
    <Careers />
    <Contact />
  </Layout>
)

export default IndexPage
