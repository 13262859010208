import React from "react"
import { Col, Container, Row } from "styled-bootstrap-grid"
import styled, { keyframes } from "styled-components"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"

const AboutBlock = styled.div`
  background: linear-gradient(
          13deg,
          #2a2444,
          #393269 18.23%,
          #6f4268 38.02%,
          #a45467 53.65%,
          #d96666 74.48%,
          #f599af
  );
`

const Title = styled.div`
    color: ${colors.white};
    font-size: 48px;
    line-height: 71px;
    font-family: Helvetica, sans-serif;
    font-weight: 100;
    padding: 3em 15px;
    text-align: center;
    b {
      font-weight: 400;
    }
    @media (min-width: ${breakpoints.md}) {
      padding: 4em 20px;
    }
`

const coolBoxKeyframes = keyframes`
  0% {
    color: ${colors.accent3};
  }
  50% {
    color: ${colors.foreground};
  }
  100% {
    color: ${colors.accent3};
  }
`

const IrieSpan = styled.div`
  background-color: ${colors.background};
  color: ${colors.accent1};
  display: inline-block;
  padding: .25em .4em;
  font-size: 80%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  animation: ${coolBoxKeyframes} 6s linear 0s infinite;
`

const Intro = () => {
  return (
    <AboutBlock>
      <Container>
        <Row>
          <Col sm={12}>
            <Title>Something really <IrieSpan>IRIE</IrieSpan> starts here.</Title>
          </Col>
        </Row>
      </Container>
    </AboutBlock>
  )
}

export default Intro