import React from "react"
import styled, {keyframes} from "styled-components"
import colors from "../../styles/colors"

const posMouse = '6px';
const sizeTrackball = '6px';
const posTrackball = 13;
const shrinkTrackball = '0.4';

const animDuration = '8s';

const colorSlide = keyframes`
    0% { background-position: 0 100%; }
    20% { background-position: 0 0; }
    21% { background-color: ${colors.accent3}; }
    29.99% {
        background-color: ${colors.accent1};
        background-position: 0 0;
    }
    30% {
        background-color: ${colors.accent3};
        background-position: 0 100%;
    }
    50% { background-position: 0 0; }
    51% { background-color: ${colors.accent3}; }
    59% {
        background-color: ${colors.accent1};
        background-position: 0 0;
    }
    60% {
        background-color: ${colors.accent3};
        background-position: 0 100%;
    }
    80% { background-position: 0 0; }
    81% { background-color: ${colors.accent3}; }
    90%, 100% { background-color: ${colors.accent1}; }
`

const trackBallSlide = keyframes`
    0% {
      opacity: 1;
      transform: scale(1) translateY(-${posTrackball}px);
    }
    6% {
      opacity: 1;
      transform: scale(0.9) translateY(${posTrackball/4}px);
    }
    14% {
      opacity: 0;
      transform: scale(${shrinkTrackball}) translateY(${posTrackball*2}px);
    }
    15%, 19% {
      opacity: 0;
      transform: scale(${shrinkTrackball}) translateY(-${posTrackball}px);
    }
    28%, 29.99% {
      opacity: 1;
      transform: scale(1) translateY(-${posTrackball}px);
    }
    30% {
      opacity: 1;
      transform: scale(1) translateY(-${posTrackball}px);
    }
    36% {
      opacity: 1;
      transform: scale(0.9) translateY(${posTrackball/4}px);
    }
    44% {
      opacity: 0;
      transform: scale(${shrinkTrackball}) translateY(${posTrackball*2}px);
    }
    45%, 49% {
      opacity: 0;
      transform: scale(${shrinkTrackball}) translateY(-${posTrackball}px);
    }
    58%, 59.99% {
      opacity: 1;
      transform: scale(1) translateY(-${posTrackball}px);
    }
    60% {
      opacity: 1;
      transform: scale(1) translateY(-${posTrackball}px);
    }
    66% {
      opacity: 1;
      transform: scale(0.9) translateY(${posTrackball/4}px);
    }
    74% {
      opacity: 0;
      transform: scale(${shrinkTrackball}) translateY(${posTrackball*2}px);
    }
    75%, 79% {
      opacity: 0;
      transform: scale(${shrinkTrackball}) translateY(-${posTrackball}px);
    }
    88%, 100% {
      opacity: 1;
      transform: scale(1) translateY(-${posTrackball}px);
    }
`

const nudgeMouse = keyframes`
  0% { transform: translateY(0); }
  20% { transform: translateY(${posMouse}); }
  30% { transform: translateY(0); }
  50% { transform: translateY(${posMouse}); }
  60% { transform: translateY(0); }
  80% { transform: translateY(${posMouse}); }
  90% { transform: translateY(0); }
`

const Mouse = styled.div`
    cursor: pointer;
    background: ${colors.accent3} linear-gradient(
        transparent 0%,
        transparent 50%,
        ${colors.accent1} 50%,
        ${colors.accent1} 100%
    );
    
    position: relative;
    width: 33px;
    height: 58px;
    border-radius: 100px;
    background-size: 100% 200%;
    animation: ${colorSlide} ${animDuration} linear infinite, ${nudgeMouse} ${animDuration} ease-out infinite;
    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        margin: auto;
    }
    &:before {
        width: 27px;
        height: 52px;
        background-color: ${colors.background};
        border-radius: 100px;
    }
    &:after {
        background-color: ${colors.accent1};
        width: ${sizeTrackball};
        height: ${sizeTrackball};
        border-radius: 100%;
        animation: ${trackBallSlide} ${animDuration} linear infinite;
    }
`

const ScrollIcon = () => <Mouse />

export default ScrollIcon