import React from "react"
import styled, { keyframes } from "styled-components"
import colors from "../../styles/colors"
import SectionTitle from "../Core/SectionTitle";
import Circle from "../Layout/Circle";

const ContactUsBlock = styled.div`
  padding: 6em 0 6em 0;
  background: linear-gradient(27deg, #a14e62, #6f4268 74.48%, #443b6b);
  position: relative;
  z-index: -1;
`

const coolBoxKeyframes = keyframes`
  0% {
    color: ${colors.accent3};
  }
  50% {
    color: ${colors.background};
  }
  100% {
    color: ${colors.accent3};
  }
`

const Subtitle = styled.div`
    color: #FFFFFF;
    font-size: 38px;
    line-height: 1.2;
    font-family: Helvetica,sans-serif;
    font-weight: bold;
    text-align: center;
    margin-top: 0.4em;
`
const Link = styled.a`
  font-weight: 700;
  animation: ${coolBoxKeyframes} 6s linear 0s infinite;
  text-decoration: none;
`

const Contact = () => {
  return (
    <ContactUsBlock>
        <Circle color={"linear-gradient( 92deg,#2a2444,#393269 18.23%,#6f4268 38.02%,#a45467 53.65%,#D96689)"} width={16} height={16} left={2} top={-12}/>
        <SectionTitle text={"Get in touch"} color={colors.background}/>
        <Subtitle>Just say hai <br /><Link target="_blank" rel="noopener noreferrer" href={"mailto:hello@irietech.net"}>hello@irietech.net</Link></Subtitle>
    </ContactUsBlock>
  )
}

export default Contact